import React, { useState, useEffect } from "react"
import * as R from "ramda"
import { graphql, Link, navigate } from "gatsby"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import { BgImage } from "gbimage-bridge"
import LayoutController from "../components/layoutController"

import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import Seo from "../components/seo"
import LiftContent from "../components/liftContent"
import { useAuth } from "../firebase"
import * as styles from "./categoryPage.module.scss"
import AMDDialog from "../components/dialog"

const CategoryPage = ({ data, location }) => {
  const microCopyTexts = useMicroCopyFi
  // UI texts
  const labelEnter = GetMicroCopy(microCopyTexts, "yleinenAstuSisään")
  // end UI texts
  const labelPremisesFolder = GetMicroCopy(microCopyTexts, "polkuKohteet")
  const labelArticlesFolder = GetMicroCopy(microCopyTexts, "polkuArtikkelit")
  const labelPressReleasesFolder = GetMicroCopy(
    microCopyTexts,
    "polkuTiedotteet"
  )
  const labelUpcomingExpoFolder = GetMicroCopy(
    microCopyTexts,
    "polkuTulevatMessut"
  )
  const labelReadMore = GetMicroCopy(microCopyTexts, "yleinenLueLisää")
  const labelParticipate = GetMicroCopy(microCopyTexts, "yleinenOsallistu")
  const labelParticipateFolder = GetMicroCopy(microCopyTexts, "polkuOsallistu")
  const labelFamiliarize = GetMicroCopy(microCopyTexts, "yleinenTutustu")
  const labelFamiliarizeFolder = GetMicroCopy(microCopyTexts, "polkuOsallistu")
  const labelThanksParticipate = GetMicroCopy(
    microCopyTexts,
    "yleinenKiitosOsallistumisesta"
  )
  const labelSeeOtherContests = GetMicroCopy(
    microCopyTexts,
    "yleinenTutustuKilpailuihin"
  )
  // end UI texts
  const { user, loading } = useAuth()
  const [choice, setChoice] = useState("items")

  let itemsTab = choice === "items" ? "choice" : "choice-notselected"
  let additionalItemsTab =
    choice === "additionalItems" ? "choice" : "choice-notselected"

  const pageContent = R.path(["contentfulContent"], data)
  const language = R.path(["node_locale"], pageContent)

  const category = R.path(["category"], pageContent)
  const fair = R.path(["fair"], pageContent)
  const premises = R.path(["premises", "nodes"], data)
  const articles = R.path(["articles", "nodes"], data)
  const pressreleases = R.path(["pressreleases", "nodes"], data)
  const basicPages = R.path(["basicPages", "nodes"], data)

  // If fair is defined, filter premises according to that

  const premises_filtered = premises.filter(item =>
    fair ? item.kategoria === fair : true
  )

  // Filter basicPages to get only upcoming fairs

  const basicPages_filtered = basicPages.filter(
    item => item.folderSlug === "tulevat-messut"
  )

  // Colour options for bg image

  const {
    shape_lightGreen,
    shape_darkGreen,
    shape_yellow,
    shape_pink,
    shape_lightBlue,
  } = data

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const shapeDarkGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_darkGreen
  )
  const shapeYellow = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_yellow
  )
  const shapePink = R.path(["childImageSharp", "gatsbyImageData"], shape_pink)
  const shapeLightBlue = R.path(
    ["childImageSharp", "fgatsbyImageDatauid"],
    shape_lightBlue
  )

  // Map out items based on page category
  let items = []
  let action = ""
  let folder = ""
  let additionalItems = []
  let additionalItemsAction = ""
  let additionalItemsFolder = ""
  let additionalItemsCategory = ""

  const vote_pic_messukoti = R.path(
    ["vote_pic_messukoti", "childImageSharp", "gatsbyImageData"],
    data
  )
  const vote_pic_sisustuskierros = R.path(
    ["vote_pic_sisustuskierros", "childImageSharp", "gatsbyImageData"],
    data
  )
  const vote_pic_historianhavinaa = R.path(
    ["vote_pic_historianhavinaa", "childImageSharp", "gatsbyImageData"],
    data
  )
  const vote_pic_messumuisto = R.path(
    ["vote_pic_messumuisto", "childImageSharp", "gatsbyImageData"],
    data
  )
  const vote_pic_asumisenhaave = R.path(
    ["vote_pic_asumisenhaave", "childImageSharp", "gatsbyImageData"],
    data
  )
  const palautenosto = R.path(
    ["palautenosto", "childImageSharp", "gatsbyImageData"],
    data
  )

  if (category === "Kohteet") {
    items = premises_filtered
    action = labelEnter
    folder = `${labelPremisesFolder}`

    // Sort by two first digits in item name.
    items.forEach(item => {
      return (item.nr = parseInt(item.name.substring(0, 2)))
    })
    const sortByNameCaseInsensitive = R.sortBy(R.prop("nr"))
    items = sortByNameCaseInsensitive(items)

    additionalItems = articles.filter(
      item => item.kategoria === "Sisustuskierros"
    )
    additionalItemsCategory = "Sisustuskierrokset"
    additionalItemsAction = labelEnter
    additionalItemsFolder = `${labelArticlesFolder}`
  } else if (category === "Kestävä asuminen") {
    items = articles.filter(
      item =>
        item.kategoria === "Kestävä asuminen" ||
        item.kategoria === "Rakentaminen" ||
        item.kategoria === "Remontointi" ||
        item.kategoria === "Vastuullisuus" ||
        item.kategoria === "Taide" ||
        item.kategoria === "Kyläkaupunki"
    )
    action = labelReadMore
    folder = `${labelArticlesFolder}`
  } else if (category === "Inspiroidu ja innostu") {
    items = articles.filter(item => item.kategoria === "Inspiroidu ja innostu")
    action = labelReadMore
    folder = `${labelArticlesFolder}`
  } else if (category === "Tarjoukset") {
    items = articles.filter(item => item.kategoria === "Tarjoukset")
    action = labelReadMore
    folder = `${labelArticlesFolder}`
  } else if (category === "Inspiraatiovideot") {
    items = articles.filter(item => item.kategoria === "Inspiraatiovideot")

    // Sort by two first digits in item name.
    items.forEach(item => {
      return (item.nr = parseInt(item.title.substring(0, 2)))
    })
    const sortByNameCaseInsensitive = R.sortBy(R.prop("nr"))
    items = sortByNameCaseInsensitive(items)

    action = labelReadMore
    folder = `${labelArticlesFolder}`
  } else if (category === "Huvila & Huussi") {
    items = articles.filter(item => item.kategoria === "Huvila & Huussi")
    action = labelReadMore
    folder = `${labelArticlesFolder}`
  } else if (category === "Sisustuskierrokset") {
    items = articles.filter(item => item.kategoria === "Sisustuskierros")
    action = labelEnter
    folder = `${labelArticlesFolder}`
  } else if (category === "Tiedotteet") {
    items = pressreleases
    action = labelReadMore
    folder = `${labelPressReleasesFolder}`
  } else if (category === "Äänestykset") {
    items = [
      {
        mainImage: {
          fluid: vote_pic_messukoti,
        },
        title: "Paras messukoti",
        contentTextShort:
          "Äänestä virtuaalisten Asuntomessujen paras messukoti",
        slug: "paras-messukoti",
      },
      {
        mainImage: {
          fluid: vote_pic_sisustuskierros,
        },
        title: "Inspiroivin sisustuskierros",
        contentTextShort:
          "Äänestä virtuaalisten Asuntomessujen inspiroivin sisustuskierros",
        slug: "inspiroivin-sisustuskierros",
      },
      {
        mainImage: {
          fluid: vote_pic_asumisenhaave,
        },
        title: "Jaa asumisen haaveesi",
        contentTextShort:
          "Kerro, millainen olisi unelmien kotisi? Olisiko se maalla vai kaupungissa? Tai ehkä molemmissa?",
        slug: "jaa-asumisen-haaveesi",
      },
      {
        mainImage: {
          fluid: vote_pic_messumuisto,
        },
        title: "Jaa oma messumuistosi",
        contentTextShort: "Millaisia muistoja sinulla on Asuntomessuilta",
        slug: "jaa-oma-messumuistosi",
      },
      {
        mainImage: {
          fluid: vote_pic_historianhavinaa,
        },
        title: "Sykähdyttävin Asuntomessuvuosi",
        contentTextShort: "Millaisia muistoja sinulla on Asuntomessuilta",
        slug: "historian-havinaa",
      },
    ]
    action = labelParticipate
    folder = `${labelParticipateFolder}`
  } else if (category === "Messuvuodet") {
    items = basicPages_filtered
    action = labelFamiliarize
    folder = `${labelUpcomingExpoFolder}`

    // Sort by last four characters parsed as a number.
    items.forEach(item => {
      return (item.nr = parseInt(item.title.substring(item.title.length - 4)))
    })
    const sortByNameCaseInsensitive = R.sortBy(R.prop("nr"))
    items = sortByNameCaseInsensitive(items)
  }

  // Gatsby background images seem to jump around during rendering. Static backgroundsize helps.
  // The following stuff helps to take care of this problem and defines two sizes for mobile/laptop.
  let shapeColor = ""
  let shapeSize = ""
  let shapeMinHeight = ""
  let titleMarginBottom = ""

  const title = R.path(["title"], pageContent)
  const color = R.path(["vri"], pageContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)

  // Youtube embedly help
  const regexEmbedlyText = new RegExp("Embedded content: (.*)", "g")
  const regexYouTube = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/g
  let textContent = R.path(["contentText", "contentText"], pageContent)
  if (textContent) {
    textContent = textContent
      .replace(regexEmbedlyText, "")
      .replace(regexYouTube, "https://youtu.be/$1?rel=0")
  }

  const contentTextShort = R.path(["contentTextShort"], pageContent)

  const mobileSizeDetected = useMediaQuery("(max-width:700px)")

  shapeMinHeight = mobileSizeDetected ? "41px" : "190px"
  titleMarginBottom = mobileSizeDetected ? "-5px" : "20px"

  if (color === "Vihreä") {
    shapeColor = shapeLightGreen
    shapeSize = mobileSizeDetected ? "60px 41px" : "190px 129px"
  }
  if (color === "Tummanvihreä") {
    shapeColor = shapeDarkGreen
    shapeSize = mobileSizeDetected ? "62px 41px" : "190px 129px"
  }
  if (color === "Keltainen") {
    shapeColor = shapeYellow
    shapeSize = mobileSizeDetected ? "62px 41px" : "193px 129px"
  }
  if (color === "Sininen") {
    shapeColor = shapeLightBlue
    shapeSize = mobileSizeDetected ? "62px 41px" : "191px 128px"
  }
  if (color === "Pinkki") {
    shapeColor = shapePink
    shapeSize = mobileSizeDetected ? "62px 41px" : "203px 135px"
  }

  const thankyou = location.search === "?thankyou"

  useEffect(() => {
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF 'user' changes (from null to not null)
    if (
      textContent &&
      textContent.indexOf("Virtuaaliset messukotikierrokset") !== -1
    ) {
      // loading = false, user = null, lead user to login page.
      if (!loading && !user) {
        navigate("/login")
      }
    }
  }, [loading, user])

  return (
    <LayoutController language={language}>
    <Box>
      {textContent &&
        textContent.indexOf("Virtuaaliset messukotikierrokset") !== -1 && (
          <AMDDialog />
        )}
      <Seo
        seoTitle={title}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Box
        className={styles.box}
        style={{
          marginBottom: "200px",
          padding: "0 20px",
          maxWidth: "1240px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container>
          {/* {fair &&
            <Grid item sm={12}>
              <Link to={`/vuosien-varrelta`} className="navigateBack">
                <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
                  Vuosien varrelta
                    </Button>
              </Link>
            </Grid>
          } */}

          <Grid item sm={12} lg={6} className={styles.titleContainer}>
            <div
              className={styles.bgimageContainer}
              style={{ marginLeft: "-35px" }}
            >
              <BgImage
                image={shapeColor}
                style={{
                  minHeight: shapeMinHeight,
                  backgroundSize: shapeSize,
                  backgroundPositionX: "0px",
                }}
              >
                <h1
                  className={styles.header}
                  style={{ marginBottom: titleMarginBottom }}
                >
                  {thankyou ? labelThanksParticipate : title}
                </h1>
              </BgImage>
            </div>
            {!thankyou && (
              <div className={styles.markdownContainer}>
                <ReactMarkdown children={textContent} softBreak="br" />
              </div>
            )}
            {thankyou && <p>{labelSeeOtherContests}</p>}
          </Grid>
          <Grid item sm={12} lg={1}></Grid>
          <Grid item sm={12} lg={5}>
            <GatsbyImage image={mainImage} style={{ maxHeight: "337px" }} />
          </Grid>
        </Grid>

        <LiftContent pageContent={pageContent} />

        {/**
         * Additional Items
         * This functionality will allow tabs on the page where user can switch between two kinds of content (e.g. kohteet and sisustuskierrokset)
         */}
        {/* {additionalItems.length > 0 &&
          <div style={{ marginTop: '20px' }}>

            <Button onClick={() => setChoice('items')} className={itemsTab} variant="contained" color="primary" >
              {category}
            </Button>
            <Button onClick={() => setChoice('additionalItems')} className={additionalItemsTab} variant="contained" color="primary" >
              {additionalItemsCategory}
            </Button>

          </div>
        } */}

        <Grid container className={styles.itemsContainer} spacing={2}>
          {choice === "items" &&
            items.map(item => {
              let kat = R.path(["kategoria"], item)

              return (
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={4}
                  style={{ width: "100%", marginBottom: "20px" }}
                  key={R.path(["slug"], item)}
                >
                  <div style={{ width: "100%", height: "238px" }}>
                    <BgImage
                      image={R.path(["mainImage", "gatsbyImageData"], item)}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <p className="categoryText">{kat}</p>
                  <h3>{R.path(["title"], item) || R.path(["name"], item)}</h3>
                  <p>{R.path(["contentTextShort"], item)}</p>
                  <Link to={`/${folder}/${R.path(["slug"], item)}`}>
                    <Button variant="contained" color="primary">
                      {action}
                    </Button>
                  </Link>
                </Grid>
              )
            })}
          {choice === "additionalItems" &&
            additionalItems.map(item => {
              let kat = R.path(["kategoria"], item)

              return (
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={4}
                  style={{ width: "100%", marginBottom: "20px" }}
                  key={R.path(["slug"], item)}
                >
                  <div style={{ width: "100%", height: "238px" }}>
                    <BgImage
                      image={R.path(["mainImage", "gatsbyImageData"], item)}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <p className="categoryText">{kat}</p>
                  <h3>{R.path(["title"], item) || R.path(["name"], item)}</h3>
                  <p>{R.path(["contentTextShort"], item)}</p>
                  <Link
                    to={`/${additionalItemsFolder}/${R.path(["slug"], item)}`}
                  >
                    <Button variant="contained" color="primary">
                      {additionalItemsAction}
                    </Button>
                  </Link>
                </Grid>
              )
            })}

          {/* <Grid item sm={12} md={12} lg={6} style={{ marginBottom: '20px' }}>
            <Box style={{ width: '100%', minHeight: '300px', textAlign: 'center' }}>
              <Backgroundimage fluid={palautenosto} style={{ padding: '30px', position: 'relative', textAlign: 'center', width: '100%', backgroundPositionY: '0px', minWidth: '100%', backgroundSize: 'contain' }}>
                <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                  <div className={styles.palautenostodiv} style={{ maxWidth: '300px' }}>
                    <h2 className="palautenosto">Haluatko antaa meille palautetta?</h2>
                    <p>Mikä toimii, mikä ei? Ruusut ja risut ovat yhtälailla tervetulleita, joten kevennä mieltäsi ja kerro meille, mistä pidit tai mistä et. Kiitos!</p>
                  </div>
                  <Link to={`/palaute`} className="pink" style={{ position: 'absolute', bottom: '-10px' }}>
                    <Button variant="contained" color="primary">
                      Anna palautetta
                    </Button>
                  </Link>
                </div>
              </Backgroundimage>


            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default CategoryPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query categoryPageQuery($slug: String) {
    contentfulContent: contentfulKategoriasivu(slug: { eq: $slug }) {
      id
      title
      node_locale
      slug
      vri
      contentText {
        contentText
      }
      contentTextShort
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      category
      fair
      liftContent {
        ... on Node {
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
    premises: allContentfulKohdesivu(
      filter: { slug: { ne: "jaerjestelmaetiedosto" } }
    ) {
      nodes {
        id
        kategoria
        slug
        node_locale
        contentTextShort
        name
        mainImage {
          id
          file {
            url
          }
          gatsbyImageData(quality: 80, layout: CONSTRAINED)
        }
      }
    }
    articles: allContentfulArtikkelisivu(
      filter: { slug: { ne: "jaerjestelmaetiedosto" } },
      sort: {fields: [createdAt], order: DESC}
      ) {
      nodes {
        createdAt
        id
        title
        slug
        node_locale
        contentTextShort
        kategoria
        mainImage {
          id
          file {
            url
          }
          gatsbyImageData(quality: 80)
        }
      }
    }
    pressreleases: allContentfulTiedotesivu(
      filter: { slug: { ne: "jaerjestelmaetiedosto" } }
    ) {
      nodes {
        id
        title
        node_locale
        slug
        contentTextShort
        releaseDate
        keywords
        mainImage {
          id
          file {
            url
          }
          gatsbyImageData(quality: 80, layout: CONSTRAINED)
        }
      }
    }
    basicPages: allContentfulPerussivu(
      filter: { slug: { ne: "jaerjestelmaetiedosto" } }
    ) {
      nodes {
        folder
        folderSlug
        slug
        id
        node_locale
        title
        contentTextShort
        mainImage {
          gatsbyImageData(quality: 80, layout: CONSTRAINED)
          file {
            url
          }
        }
      }
    }
    shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_darkGreen: file(relativePath: { eq: "shape_darkGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_yellow: file(relativePath: { eq: "shape_yellow.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_pink: file(relativePath: { eq: "shape_pink.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_lightBlue: file(relativePath: { eq: "shape_lightBlue.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    vote_pic_historianhavinaa: file(
      relativePath: { eq: "sam_historiikki_kuvat_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    vote_pic_sisustuskierros: file(
      relativePath: { eq: "Kohde 16 Finnlamelli Linnamajuri 1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    vote_pic_messumuisto: file(
      relativePath: { eq: "20090624143223118_0001_messutytot.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    vote_pic_messukoti: file(relativePath: { eq: "huvilajahuussi2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    vote_pic_asumisenhaave: file(relativePath: { eq: "Lumi-9665.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    palautenosto: file(relativePath: { eq: "Group 256.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    kerava: file(relativePath: { eq: "kerava_thumb.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    naantali: file(relativePath: { eq: "naantali_thumb.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    loviisa: file(relativePath: { eq: "loviisa_thumb.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
    oulu: file(relativePath: { eq: "oulu_thumb.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 60
          width: 400
          layout: CONSTRAINED
        )
      }
    }
  }
`
